<i18n lang="yaml">
ja:
  visitor-count: '現在{count}人が参加中'
  button: ルームに入室
en:
  visitor-count: 'Currently {count} people are participating.'
  button: ENTRY TO A ROOM
</i18n>
<template>
  <!-- NOTE: positionChannel === undefinedになるケースは実装ミスなのでelseはいらない -->
  <template v-if="positionChannel">
    <HaBaseDialog v-if="isOpen" class="ho-talkroom-entrance-dialog">
      <div ref="modal" class="modal">
        <p class="title">{{ positionChannel.name }}</p>
        <div class="image-wrapper">
          <HaBaseImage class="image" :src="thumbnailUrl" />
          <HaLink
            class="user-wrapper"
            :to="`/profile/${hostUser?.vketId || ''}`"
            redirect
          >
            <div ref="userWrapper" class="user">
              <HmUserIcon class="icon" :src="hostUser?.icon.url || ''" />
              <p class="name">{{ hostUser?.name || '' }}</p>
            </div>
          </HaLink>
        </div>
        <p class="visitor-count">
          {{ i18n.t('visitor-count', { count: entryCount }) }}
        </p>
        <div class="visitor-wrapper">
          <div class="list-icon">
            <HmUserIcon
              v-for="(user, index) in participantUserList"
              :key="`icon-${index}`"
              class="icon"
              :src="user.icon"
            />
          </div>
        </div>
        <HoMyVketButton
          class="button"
          :disabled="!props.housingWorld"
          @click="onEnterHousingWorld"
          >{{ i18n.t('button') }}</HoMyVketButton
        >
      </div>
    </HaBaseDialog></template
  >
</template>

<script lang="ts" setup>
import { DeepReadonly } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { breakpoints } from '@/composables/useBreakpoints'
import { PositionChannel } from '@/models/channelSession'
import { HousingWorld } from '@/models/housingWorld'

import type { EnterHousingWorld } from '@/composables/useInGame'

const i18n = useI18n()

type Props = {
  isOpen: boolean
  room: DeepReadonly<PositionChannel> | null
  housingWorld: DeepReadonly<HousingWorld> | null
}
type Emits = {
  (emit: 'close'): void
  (e: 'enterHousingWorld', options: EnterHousingWorld): void
}
const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modal = ref<HTMLElement | null>(null)
const userWrapper = ref<HTMLElement | null>(null)
const isSp = breakpoints.smaller('sp')

const positionChannel = computed(() => props.room)
const users = computed(() => props.room?.users)

// Note: ユーザー名を表示しているエリアは動的に位置を調整する
const userWrapperBottom = computed(() => {
  if (!userWrapper.value) return 0
  return `-${userWrapper.value.clientHeight / 2}px`
})

const participantUserList = computed(() => {
  if (isSp.value) return users.value?.slice(0, 6) || []
  return users.value?.slice(0, 12) || []
})
const hostUser = computed(() => props.housingWorld?.user || null)
const thumbnailUrl = computed(() => props.housingWorld?.thumbnail.url || '')
const entryCount = computed(() => props.room?.users.length || 0)

// events
onClickOutside(modal, (_) => {
  emit('close')
})
const onEnterHousingWorld = () => {
  emit('enterHousingWorld', {
    worldId: props.housingWorld?.worldId || '',
    channelId: positionChannel.value?.channelId,
  })
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$modal-content-offset: v.space(5);

.ho-talkroom-entrance-dialog[class] {
  padding: (v.$header-height-pc + $modal-content-offset) $modal-content-offset
    $modal-content-offset;
  @include m.tb() {
    padding-bottom: v.$sp-toolbar-height + $modal-content-offset;
    padding-top: v.$header-height-pc + $modal-content-offset;
  }
  @include m.sp() {
    padding-top: v.$header-height-sp + $modal-content-offset;
  }

  :deep(.dialog-window) {
    background-color: v.$white;
    border-radius: 5px;
    margin: 0 v.space(4);
    min-width: 640px;
    width: 35%;

    @include m.sp {
      min-width: auto;
      width: 100%;
    }

    .content {
      padding: 0;
      width: 100%;
    }

    .error {
      display: none;
    }
  }
}

.modal {
  @extend %scroll-bar;
  max-height: calc(100vh - v.$header-height-pc - $modal-content-offset * 2);
  overflow: auto;
  padding: v.space(8);
  @include m.tb() {
    max-height: calc(
      100vh - v.$header-height-pc - v.$sp-toolbar-height - $modal-content-offset *
        2
    );
  }
  @include m.sp() {
    max-height: calc(
      100vh - v.$header-height-sp - v.$sp-toolbar-height - $modal-content-offset *
        2
    );
    padding: v.space(4) v.space(4) v.space(6) v.space(4);
  }
}

.title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 20px;
  font-weight: 900;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;

  @include m.sp {
    font-size: 16px;
  }
}

.image-wrapper {
  margin-top: v.space(2);
  position: relative;

  @include m.sp {
    margin-top: v.space(1);
  }

  > .image {
    aspect-ratio: 16/9;
    border-radius: 5px;
    object-fit: cover;
  }
}

.user-wrapper {
  align-items: center;
  background: v.$gray;
  border-radius: 50px;
  bottom: v-bind(userWrapperBottom);
  color: v.$white;
  display: flex;
  left: 50%;
  padding: 0 v.space(2);
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 60%;

  @include m.sp {
    margin: 0 5.5px;
  }

  > .user {
    align-items: center;
    color: v.$text-body;
    display: flex;
  }

  > .user > .icon {
    height: 28px;
    margin: 5px 0;
    width: 28px;

    @include m.sp {
      height: 27px;
      width: 27px;
    }
  }

  > .user > .name {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    -webkit-line-clamp: 1;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.visitor-count {
  font-size: 12px;
  margin-top: v.space(10);
}

.visitor-wrapper {
  background: v.$gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: v.space(1);
  padding: v.space(4);
  @include m.sp {
    padding: v.space(2);
  }
}

.list-icon {
  display: grid;
  gap: v.space(4) 0;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;

  > .icon {
    height: 56px;
    width: 56px;
    @include m.sp {
      height: 45px;
      width: 45px;
    }
  }
}

.button {
  font-size: 16px;
  font-weight: 900;
  margin-top: v.space(4);
  padding: v.space(2.5);
  width: 90%;

  @include m.sp {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
  }
}
</style>
