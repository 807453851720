<i18n lang="yaml">
ja:
  title: トークルーム新規作成
  text: トークルーム名
  button: 作成して入室
  default-room-name: '{name}の部屋'
en:
  title: Create new talk room
  text: Talk Room Name
  button: CREATE AND ENTER
  default-room-name: "{name}'s room"
</i18n>
<template>
  <HaBaseDialog
    v-if="isOpen"
    class="ho-talkroom-create-dialog"
    @close="closeDialog"
  >
    <div ref="modal" class="modal">
      <HoTitle type="h3" class="title">{{ i18n.t('title') }}</HoTitle>
      <div class="description">
        <p>
          {{ i18n.t('text') }}
        </p>
        <p class="count">{{ inputValue.length }}/40</p>
      </div>
      <HoMyVketInputText
        v-model:value="inputValue"
        class="input"
        :validator-name="`${i18n.t('text')}`"
        validator-rules="required|max:40"
        :max="40"
      />
      <div class="button-wrapper">
        <HoMyVketButton
          :disabled="isButtonDisabled"
          class="button"
          @click="onCreate"
          >{{ i18n.t('button') }}</HoMyVketButton
        >
      </div>
    </div>
  </HaBaseDialog>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { useForm } from 'vee-validate'

const i18n = useI18n()
const { errors } = useForm()

type Props = {
  userName: string
  isOpen: boolean
}
type Emits = {
  (emit: 'close'): void
  (emit: 'createRoom', roomName: string): void
}
const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const inputValue = ref('')
const modal = ref<HTMLElement | null>(null)

// NOTE: userNameが遅れて取得されるので取得時点で初期値を調整する
watch(props, () => {
  inputValue.value = i18n.t('default-room-name', { name: props.userName })
})

/** バリデーションエラー */
const isError = computed(() => {
  return Object.keys(errors.value).length !== 0
})
const isButtonDisabled = computed(() => !inputValue.value || isError.value)

// events
const onCreate = () => {
  // ダイアログを開いた直後から文字の状態だとエラーが出ず実行できるのでreturnする
  if (!inputValue.value) return
  // Note: dev toolで意図的に40文字以上入力できるので削除する
  if (isError.value) return
  emit('createRoom', inputValue.value.slice(0, 40))
}

onClickOutside(modal, (_) => {
  emit('close')
})

const closeDialog = () => {
  emit('close')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$modal-content-offset: v.space(5);

.ho-talkroom-create-dialog[class] {
  padding: (v.$header-height-pc + $modal-content-offset) $modal-content-offset
    $modal-content-offset;
  @include m.tb() {
    padding-bottom: v.$sp-toolbar-height + $modal-content-offset;
    padding-top: v.$header-height-pc + $modal-content-offset;
  }
  @include m.sp() {
    padding-top: v.$header-height-sp + $modal-content-offset;
  }

  :deep(.dialog-window) {
    background-color: v.$white;
    border-radius: 5px;
    margin: 0 v.space(4);
    min-width: 640px;
    width: 35%;

    @include m.sp {
      min-width: auto;
      width: 100%;
    }

    .content {
      padding: 0;
      width: 100%;
    }
  }
}

.modal {
  @extend %scroll-bar;
  max-height: calc(100vh - v.$header-height-pc - $modal-content-offset * 2);
  overflow: auto;
  @include m.tb() {
    max-height: calc(
      100vh - v.$header-height-pc - v.$sp-toolbar-height - $modal-content-offset *
        2
    );
  }
  @include m.sp() {
    max-height: calc(
      100vh - v.$header-height-sp - v.$sp-toolbar-height - $modal-content-offset *
        2
    );
  }
}

.title {
  background: v.$gray;
  border-radius: 10px 10px 0 0;

  font-size: 20px;
  padding: v.space(8) v.space(8) v.space(4);

  @include m.sp {
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    padding: v.space(4) v.space(6);
  }
}

.description {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: v.space(4) v.space(8) v.space(1);

  @include m.sp {
    padding: v.space(4) v.space(4) v.space(1) v.space(4);
  }

  > .count {
    color: v.$gray-5;
  }
}

.input {
  padding: 0 v.space(8);
  @include m.sp {
    padding: 0 v.space(4);
  }

  :deep(.label) {
    background-color: v.$gray;
  }

  :deep(.error) {
    margin-top: 0;
  }
}

.button-wrapper {
  padding: v.space(4) v.space(20) v.space(8);

  @include m.sp {
    padding: 0 v.space(4) v.space(6) v.space(4);
  }
}

.button {
  font-size: 16px;
  font-weight: 900;
  width: 100%;

  @include m.sp {
    font-size: 15px;
    font-weight: 700;
  }
}
</style>
